html {
    height: stretch;
    height: -webkit-fill-available;
    height: '100vh'
}

html,
body,
#root {
    height: 100%
}